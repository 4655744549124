import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { appRoutes } from '@constants/routes/app.routes';
import { TranslateModule } from '@dv/translate';
import { environment } from '@environment/environment';
import { httpErrorInterceptor } from '@interceptors/http-error/http-error.interceptor';
import { httpTokenInterceptor } from '@interceptors/http-token/http-token.interceptor';
import * as Sentry from '@sentry/angular';
import { TraceService } from '@sentry/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(
      withInterceptors([httpTokenInterceptor, httpErrorInterceptor]),
    ),
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatSnackBarModule,
      MatDialogModule,
      TranslateModule.forRoot({
        appKey: environment.appKey,
        baseUrl: environment.translateURL,
        debug: !environment.production,
      }),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      NgxGoogleAnalyticsModule.forRoot(
        environment.GTM_ID,
        [],
        `https://googletagmanager.com/gtm.js?id=${environment.GTM_ID}`,
      ),
      NgxGoogleAnalyticsRouterModule,
    ),
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: LOCALE_ID, useValue: 'en-us' },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
  ],
};

export function getLocalStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}
