/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { USER_ROUTE } from '@app/constants/routes/route-paths.constant';
import { AlertToasterService } from '@app/services/alert-toaster/alert-toaster.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { ErrorCode } from '@constants/app.constant';
import {
  MESSAGES_TRANSLATION_KEYS,
  TOASTER_MESSAGES,
} from '@constants/message.constant';
import { TranslateService } from '@dv/translate';
import { NOTICE_TYPE } from '@dvm/components';
import { LoggerService } from '@services/logger/logger.service';
import { catchError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next,
) => {
  const toasterService = inject(AlertToasterService);
  const translateService = inject(TranslateService);
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorToastInInterceptor = true;
      if (
        ['POST', 'PATCH'].includes(request.method) &&
        request.body?.error_toast_in_interceptor != null &&
        !request.body?.error_toast_in_interceptor
      ) {
        errorToastInInterceptor = false;
        delete request.body.error_toast_in_interceptor;
      }
      if (error.status === ErrorCode.unauthorized) {
        const message = translateService.t(
          MESSAGES_TRANSLATION_KEYS.tokenExpired,
          TOASTER_MESSAGES.tokenExpired,
        );
        toasterService.displaySnackBarWithoutTranslation(
          message,
          NOTICE_TYPE.DANGER,
        );
        authService.logoutUserSession();
      } else if (error.status === ErrorCode.notAcceptable) {
        router.navigate([USER_ROUTE.ERROR_404]);
      } else if (errorToastInInterceptor) {
        const message = translateService.t(
          MESSAGES_TRANSLATION_KEYS.internalServerError,
          TOASTER_MESSAGES.internalServerError,
        );
        toasterService.displaySnackBarWithoutTranslation(
          error?.error?.message || error.statusText || message,
          NOTICE_TYPE.DANGER,
        );
      }
      const err = new HttpErrorResponse({
        error,
        statusText: error.message,
        status: error.status,
      });
      LoggerService.error(err);

      throw err;
    }),
  );
};
