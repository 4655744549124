import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class SWUpdateService {
  private swUpdate = inject(SwUpdate);
  private destroyRef = inject(DestroyRef);

  initSWUpdate(): void {
    this.versionUpdates();
  }

  private versionUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => {
          if (event.type === 'VERSION_READY') {
            this.flushAndReload();
          }
        });
    }
  }

  private flushAndReload() {
    // Reload the page to update to the latest version.
    location.reload();
  }
}
