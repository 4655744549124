import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { CLIENTURL } from '@app/constants/app.constant';
import { CurrentUserStateService } from '@app/services/current-user/current-user-state/current-user-state.service';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { environment } from '@environment/environment';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

export const httpTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const localStorageService = inject(LocalStorageService);
  const currentUserStateService = inject(CurrentUserStateService);

  const token = localStorageService.get(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN);
  if (request.url.includes(environment.appsAPI)) {
    return next(request);
  }
  if (token) {
    request = request.clone({
      setHeaders: {
        'X-Token': token,
      },
    });
  }
  let requestUrl = `${environment.hostName}${environment.restAPI}${request.url}`;

  if (requestUrl.includes(CLIENTURL)) {
    const clientId = currentUserStateService.clientId();
    requestUrl = requestUrl.replace(CLIENTURL, clientId);
  }

  request = request.clone({
    url: requestUrl,
  });

  return next(request);
};
