import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';
import { inviteGuard } from '@app/guards/invite/invite.guard';
import { loginGuard } from '@app/guards/login/login.guard';
import { CurrentUserStateService } from '@app/services/current-user/current-user-state/current-user-state.service';
import { APP_ROUTE, AUTH_ROUTE } from '@constants/routes/route-paths.constant';
import { TranslateService } from '@dv/translate';

export const appRoutes: Routes = [
  {
    path: APP_ROUTE.AUTH,
    loadChildren: () =>
      import('@constants/routes/auth.routes').then((m) => m.authRoutes),
    canMatch: [loginGuard],
  },
  {
    path: AUTH_ROUTE.REGISTER_ACCOUNT,
    loadComponent: () =>
      import('@auth/register-account/register-account.component').then(
        (m) => m.RegisterAccountComponent,
      ),
    title: () => inject(TranslateService).t('Register'),
    canMatch: [inviteGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('@views/views.component').then((m) => m.ViewsComponent),
    canMatch: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@constants/routes/user.routes').then((m) => m.userRoutes),
      },
    ],
    resolve: { data: () => inject(CurrentUserStateService).getClients() },
  },
];
