import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { APP_ROUTE, AUTH_ROUTE } from '@constants/routes/route-paths.constant';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

export const authGuard: CanMatchFn = (_route: Route, state: UrlSegment[]) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const token = localStorageService.get(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN);
  if (token) {
    return true;
  }
  router.navigate([
    `${APP_ROUTE.AUTH}/${AUTH_ROUTE.LOGIN}`,
    { returnUrl: state.join('/') },
  ]);
  return false;
};
