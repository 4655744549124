import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeNb from '@angular/common/locales/nb';
import localeNo from '@angular/common/locales/no';
import localeSv from '@angular/common/locales/sv';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UtilityService } from '@services/utility/utility.service';
import { LANGUAGE_CONSTANTS } from './constants/app.constant';
import { PwaInstallService } from './services/pwa-install/pwa-install.service';
import { SWUpdateService } from './services/sw-update/swupdate.service';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private utilityService = inject(UtilityService);
  private swUpdateService = inject(SWUpdateService);
  private pwaInstallService = inject(PwaInstallService);

  title = 'DVTime';

  ngOnInit() {
    //for calendar header translation
    const locales = [
      { locale: localeEn, code: LANGUAGE_CONSTANTS.en },
      { locale: localeSv, code: LANGUAGE_CONSTANTS.sv },
      { locale: localeNo, code: LANGUAGE_CONSTANTS.no },
      { locale: localeNb, code: LANGUAGE_CONSTANTS.nb },
      { locale: localeFi, code: LANGUAGE_CONSTANTS.fi },
    ];
    locales.forEach(({ locale, code }) => registerLocaleData(locale, code));

    this.utilityService.setLanguage();

    this.pwaInstallService.initPwaInstall();
    this.swUpdateService.initSWUpdate();
  }
}
