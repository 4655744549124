import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

export const inviteGuard: CanMatchFn = () => {
  const localStorageService = inject(LocalStorageService);
  const authService = inject(AuthenticationService);
  const token = localStorageService.get(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN);
  if (token) {
    authService.logout(false);
  }
  return true;
};
